import axios from 'axios';

export const addFlipHistory = (params) => {
    axios.get('/api/flip/add', { params: params })
        .then((response) => {
            if (response.data.success === true)
                console.log('history add success');
        })
        .catch((error) => {
            console.log('addFlipHistory:', error);
        });
};

export const getAllStat = async (params) => {
    return new Promise((resolve, reject) => {
        axios.get('/api/flip/get_all_stat', { params: params })
            .then((response) => {
                if (response.data) {
                    console.log('get all stat success, data = ', response.data);
                    resolve(response.data);
                }
            })
            .catch((error) => {
                reject(error);
                console.log('get all stat:', error);
            });
    });
};

export const getWinRate = async (params) => {
    return new Promise((resolve, reject) => {
        axios.get('/api/flip/get_win_rate', { params: params })
            .then((response) => {
                if (response.data) {
                    console.log('get win rate success, data = ', response.data);
                    resolve(response.data);
                }
            })
            .catch((error) => {
                reject(error);
                console.log('get win rate:', error);
            });
    });
};

export const getVolumeManager = async (params) => {
    return new Promise((resolve, reject) => {
        axios.get('/api/flip/get_volume_manager', { params: params })
            .then((response) => {
                if (response.data) {
                    console.log('get volume manager success');
                    resolve(response.data);
                }
            })
            .catch((error) => {
                reject(error);
                console.log('get volume manager:', error);
            });
    });
};

export const getStatInfo = async (params) => {
    return new Promise((resolve, reject) => {
        axios.get('/api/flip/get_stat_info', { params: params })
            .then((response) => {
                if (response.data) {
                    console.log('get stat info success, data = ', response.data);
                    resolve(response.data);
                }
            })
            .catch((error) => {
                reject(error);
                console.log('get stat info:', error);
            });
    });
};