import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";

const ResponsiveHeader = () => {
  const wallet = useWallet();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    console.log("Header, toggleMenu");
    setIsOpen(!isOpen);
  };

  return (
    <header className="fixed top-0 w-full text-slate-400 z-20 bg-green-300 opacity-90">
      <div className="container mx-auto flex justify-between items-center p-4">
        <div className="flex items-center">
          <img
            src="favicon.jpeg" // Replace with your logo image path
            alt="Logo"
            className="h-[50px] mr-2 rounded-full select-none border-yellow-500 border-4"
          />
          <span className="text-[30px] text-base italic font-extrabold mr-[20px] select-none">
            Turt Flip
          </span>
        </div>

        {/* Menu for larger screens */}
        <nav className="hidden md:flex space-x-6">
          <Link to="/">
            <span
              className={`text-xl font-bold mr-3 ${pathname === "/" && "text-[#5d1aa4]"}`}
            >
              Home
            </span>
          </Link>
          <Link to="/leaderboard">
            <span
              className={`text-xl font-bold ${
                pathname === "/leaderboard" && "text-[#5d1aa4]"
              }`}
            >
              Leaderboard
            </span>
          </Link>
        </nav>

        <div className="flex items-center gap-4">
          {/* Hamburger menu button for mobile */}
          <button
            className="md:hidden focus:outline-none text-base"
            onClick={toggleMenu}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
              />
            </svg>
          </button>

          {/* Solana connection button */}
          <div className="inline-block rounded-lg select-none">
            <WalletMultiButton className="wallet-adapter-button-trigger"></WalletMultiButton>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className="relative">
        {isOpen && (
          <div className="md:hidden absolute top-0 w-full bg-white">
            <nav className="flex flex-col space-y-2 p-4 justify-start text-slate-400 text-[20px] font-bold">
              <a href="/" className="hover:text-[#5d1aa4] select-none">
                Home
              </a>
              <a href="/leaderboard" className="hover:text-[#5d1aa4] select-none">
                Leaderboard
              </a>
            </nav>
          </div>
        )}
      </div>
    </header>
  );
};

export default ResponsiveHeader;
