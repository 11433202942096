import { useState } from "react";
import Layout from "../components/Layout";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import AfterConnected from "../components/AfterConnected";

const Home = () => {
  const wallet = useWallet();
  const { connection } = useConnection();
  const [connected, setConnected] = useState(false);

  return (
    // <Layout setConnected={setConnected}>
    <div className="">
      {wallet.connected ? <AfterConnected /> : <BeforeConnected />}
    </div>
    // </Layout>
  );
};

export default Home;

const BeforeConnected = () => {

  const handlePlay = () => {
    
  }
  return (
    <div className="container w-screen h-screen flex flex-col justify-center items-center gap-8">
      <h2 className="text-2xl italic font-bold text-center mr-3 md:text-4xl text-[#4b4b14] select-none">
        #1 MOST TRUSTED PLACE TO FLIP
      </h2>
      <div className="select-none">
        <img src="/turtleCoin.png" alt="" className="w-80 select-none " />
      </div>
      {/* <button
        className="bg-[#5d1aa4] p-4 px-8 rounded-full text-white select-none cursor-pointer hover:scale-105 font-bold"
        onClick={() => {
          handlePlay();
        }}
      >
        Play Now
      </button> */}
    </div>
  );
};
