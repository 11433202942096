import React from "react";
import { Routes, Route } from "react-router-dom";
// import Footer from "./Footer";
import Home from "../pages/Home";
import LeaderBoard from "../pages/LeaderBoard";
import ResponsiveHeader from "./ResponsiveHeader";

const Layout = ({ setConnected }) => {
  return (
    <div className="flex flex-col justify-between w-screen h-screen overflow-x-hidden overflow-y-auto layout">
      <ResponsiveHeader />
      <div className="z-10 flex flex-col items-center justify-start flex-1 mt-[100px]">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/leaderboard" element={<LeaderBoard />} />
        </Routes>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
