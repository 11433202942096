import { useEffect, useState } from "react";
import TopGainerTable from "./TopGainerTable";
import PieChart from "./PieChart";
import { getAllStat, getWinRate } from "../actions";

const TodayStatCard = () => {
  const [topGainerList, setTopGainerList] = useState([]);
  const [winRate, setWinRate] = useState(null);
  useEffect(() => {
    getStat();
    getRate();
  }, []);

  const getStat = () => {
    getAllStat({ today: true })
      .then((result) => {
        setTopGainerList(result);
      })
      .catch((err) => {
        setTopGainerList([]);
      });
  };

  const getRate = () => {
    getWinRate({ today: true })
      .then((result) => {
        setWinRate(result);
      })
      .catch((err) => {
        setWinRate(null);
      });
  };

  return (
    <div className="w-full p-3 bg-white bg-opacity-50 shadow-2xl shadow-gray-600">
      <div className="flex flex-row items-center justify-center">
        <span className="text-[32px]">Today's Statistics</span>
      </div>
      <div className="flex flex-col md:flex-row w-full justify-around">
        <div className="md:w-[45%] w-full pt-4">
          <TopGainerTable
            title="Top Gainers (24 hrs)"
            dataList={topGainerList}
            headerList={[
              { title: "Wallet ID", key: "address" },
              { title: "Net Gain (SOL)", key: "netGain" }
            ]}
          />
        </div>
        <div className="md:w-[45%] w-full pt-4">
          <PieChart data={winRate} />
        </div>
      </div>
    </div>
  );
};

export default TodayStatCard;
