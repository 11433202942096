import { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const PieChart = (props) => {
    const { data } = props;
    const [chartOptions, setChartOptions] = useState(null);
    const [winRate, setWinRate] = useState('-');
    const tempOptions = {
        chart: {
            type: 'pie',
            backgroundColor: 'transparent',
        },
        title: {
            text: 'Win Vs Loss',
            style: {
                fontSize: '25px',
            },
        },
        series: [
            {
                name: 'counts',
                data: [
                    { name: 'win', y: 0, color: 'green' },
                    { name: 'loss', y: 0, color: 'red' },
                ],
            },
        ],
        plotOptions: {
            pie: {
                innerSize: '50%', // Adjusted inner size for better visibility
                dataLabels: {
                    enabled: true,
                },
            },
        },
        legend: {
            enabled: true,
        },
        credits: {
            enabled: false,
        },
    };

    useEffect(() => {
        let tempSeries = {
            name: 'results',
            data: [
                { name: 'win', y: data ? data.win : 0, color: 'green' },
                { name: 'loss', y: data ? data.loss : 0, color: 'red' },
            ],
        };
        let temp = tempOptions;
        temp.series = tempSeries;
        setChartOptions(temp);
        setWinRate(data && (data.win + data.loss) !== 0 ? (100 * data.win / (data.win + data.loss)).toFixed(2) : '-');
    }, [data]);

    return (
        <div className='flex flex-col items-center'>
            {chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} style={{ width: '100%', height: '400px' }} />}
            <div className='flex flex-col items-center justify-center'>
                <span className="text-[28px]">Today's Win Percentage</span>
                <span className="text-[35px]">{isNaN(winRate) ? '-' : winRate} %</span>
            </div>
        </div>
    );
};

export default PieChart;